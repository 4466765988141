import React from "react";
import logo from "../img/sixsigma.png";
import WhatsAppButton from "./WhatsAppButton";

function Navbar() {
  return (<>   
    <nav className="navbar">
    <div className="nav1"> <img src={logo} alt="logo" />
    <h1 className="highlight">Six-Sigma EuroPath</h1>  </div>
      
   
   <div className="nav2">

     <h5>Contactează-ne pentru <span className="highlight2"> analiza gratiuita </span> </h5>
      <WhatsAppButton/>
   </div>
     
     
    
    </nav>
    
   </>
  );
}

export default Navbar;
