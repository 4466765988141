import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
  const phoneNumber = '+40758743934';
  
  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <FaWhatsapp
      onClick={handleClick}
      className="wIcon"
      style={{ cursor: 'pointer' }}
    />
  );
};

export default WhatsAppButton;
