import React from "react";


import ScrollButton from "./ScrollButton";

function MainContent() {
  return (
    <>
     
      <ScrollButton />
      <div className="mainContent">
        
        <p className="p1">
          La  Six-Sigma EuroPath, suntem
          dedicați să ajutăm IMM-urile, administrațiile publice și primăriile să
          acceseze fonduri europene pentru a-și atinge obiectivele de
          <span className="highlight3"> creștere și dezvoltare.</span>
        </p>
        <h2>
          Vă rugăm să ne împărtășiți cerințele proiectului dumneavoastră și vom
          lua legătura cu dumneavoastră în cel mai scurt timp.
        </h2>
      </div>
    </>
  );
}

export default MainContent;
