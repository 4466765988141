import React, { useState, useEffect } from "react";


function EmailForm() {
  const [sucessMessage, setSucessMessage] = useState("");
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false); // State to track reCAPTCHA verification
  const loadRecaptchaScript = () => {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LfbfUQqAAAAADtw9E3Hb6ni6kp8BygYTjnXjiLG";
    script.async = true;
    document.body.appendChild(script);

    script.onload = initRecaptcha; // Call the initRecaptcha function once the script is loaded
  };
  const initRecaptcha = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LfbfUQqAAAAADtw9E3Hb6ni6kp8BygYTjnXjiLG", {
          action: "handleSubmit",
        })
        .then((token) => {
          // console.log(token);

          const verifyRecaptchaURL = "https://sixsigmaeuropath.ro/verify-recaptcha.php";
          const data = {
            token: token,
          };

          fetch(verifyRecaptchaURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())

            .then((data) => {
              if (data.success) {
                setIsRecaptchaVerified(true); // Set reCAPTCHA verification status
              }
            })

            .catch((error) => {
              console.error(error);
            });
        });
    });
  };
  useEffect(() => {
    loadRecaptchaScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [formData, setFormData] = useState({
    email: "",
    nume: "",
    prenume: "",
    companie: "",
    localitate: "",
    judet: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
  
    if (!formData.email.trim()) {
      errors.email = "Email-ul este necesar";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email-ul este invalid";
    }
    
    if (!formData.nume.trim()) {
      errors.nume = "Numele este necesar";
    }
    
    if (!formData.prenume.trim()) {
      errors.prenume = "Prenumele este necesar";
    }
    
    if (!formData.companie.trim()) {
      errors.companie = "Compania este necesară";
    }
    
    if (!formData.localitate.trim()) {
      errors.localitate = "Localitatea este necesară";
    }
    
    if (!formData.judet.trim()) {
      errors.judet = "Județul este necesar";
    }
    
    if (!formData.phone.trim()) {
      errors.phone = "Numărul de telefon este necesar";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Numărul de telefon trebuie să fie de 10 cifre";
    }
    
    if (!formData.message.trim()) {
      errors.message = "Mesajul este necesar";
    }
    
  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Send the form data to the backend (PHP script).
      fetch("/send-email.php", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          // Handle the response from the PHP script (e.g., show a success message).
          setSucessMessage("Mesaj trimis cu succes!");

          setFormData({
            email: "",
            nume: "",
            prenume: "",
            companie: "",
            localitate: "",
            judet: "",
            phone: "",
            message: "",
          });
        })
        .catch((error) => {
          console.error(error);
          // Handle errors (e.g., show an error message).
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <fieldset className="contactForm">
          <label>
           Email <sup>*</sup>
            <input
              title="Email"
              type="email"
              name="email"
              
              value={formData.email}
              onChange={handleChange}
            />{errors.email && <div className="error">{errors.email}</div>}
          </label>
          
          <label>
            Nume<sup>*</sup>
            <input
              title="Nume"
              type="text"
              name="nume"
              
              value={formData.nume}
              onChange={handleChange}
            />{errors.nume && <div className="error">{errors.nume}</div>}
          </label>
          
          <label>
            Prenume<sup>*</sup>
            <input
              title="Prenume"
              type="text"
              name="prenume"
              
              value={formData.prenume}
              onChange={handleChange}
            />{errors.prenume && <div className="error">{errors.prenume}</div>}
          </label>
          

          <label>
            Companie/UAT<sup>*</sup>
            <input
              title="Companie"
              type="text"
              name="companie"
              
              value={formData.companie}
              onChange={handleChange}
            />{errors.companie && <div className="error">{errors.companie}</div>}
          </label>
          
          <label>
            Localitate<sup>*</sup>
            <input
              title="Localitate"
              type="text"
              name="localitate"
              
              value={formData.localitate}
              onChange={handleChange}
            />{errors.localitate && (
            <div className="error">{errors.localitate}</div>
          )}
          </label>
          
          <label>
            Judet<sup>*</sup>
            <input
              title="Judet"
              type="text"
              name="judet"
              
              value={formData.judet}
              onChange={handleChange}
              
            />
            {errors.judet && <div className="error">{errors.judet}</div>}
          </label>
          
          <label>
            Telefon<sup>*</sup>
            <input
              title="Numar telefon "
              type="tel"
              name="phone"
              
              value={formData.phone}
              onChange={handleChange}
            /> {errors.phone && <div className="error">{errors.phone}</div>}
          </label>
         

          <label className="textareagrid">
            Mesaj<sup>*</sup>
            <textarea
              title="Type your message"
              placeholder=""
              name="message"
              
              value={formData.message}
              onChange={handleChange}
              rows={5}
              cols={64}
            ></textarea> {errors.message && <div className="error">{errors.message}</div>}
          </label>
         
        </fieldset>
        {sucessMessage && <div className="sucessMessage">{sucessMessage}</div>}
        <button className="butonTrimite" type="submit" disabled={!isRecaptchaVerified} >
          Trimite
        </button>
        <div className="button-container">
    
   </div>
      </form> 
     
    </>
  );
}

export default EmailForm;
