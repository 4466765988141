// Footer.js
import React from "react";
import FacebookButton from "./FacebookButton";
const Footer = () => {
  return (
    <footer className="footer">
      
      <div className="button-container">
    
      <FacebookButton/>
      </div>
      <p>&copy; 2024 Six-Sigma EuroPath</p>
    </footer>
  );
};

export default Footer;
