import React, { useState, useEffect } from "react";
import { PiArrowCircleUp } from "react-icons/pi";

import { PiArrowCircleDown } from "react-icons/pi";





const ScrollButton = () => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  // Function to scroll to the bottom of the page
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth"
    });
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  // Function to handle scroll and toggle button state
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;

    // Check if user is near the bottom
    if (scrollTop + windowHeight >= scrollHeight - 20) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up listener on component unmount
    };
  }, []);

  return (<> 
    <button
      className="scroll-button"
      onClick={isAtBottom ? scrollToTop : scrollToBottom}
    >
      {isAtBottom ? <PiArrowCircleUp />
 : <PiArrowCircleDown />
}
    </button>
  
    </>
  );
};

export default ScrollButton;
