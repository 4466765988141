import { useEffect } from "react"; // Import useEffect
import EmailForm from "./components/EmailForm";
import Footer from "./components/Footer";
import MainContent from "./components/MainContent";
import Navbar from "./components/Navbar";
import smoothscroll from "smoothscroll-polyfill";

function App() {
  useEffect(() => {
    // Kick off the polyfill only once when the app is mounted
    smoothscroll.polyfill();
  }, []); // Empty dependency array means it runs once when the component mounts

  return (
    <>
      <Navbar />
      <MainContent />
      <EmailForm />
      <Footer />
    </>
  );
}

export default App;
